import { QueryHookOptions } from '@apollo/client';
import { PaymentModeEnum, PaymentTermEnum } from '@app/types/paymentTypes';
import { StaffRoleEnum } from '@app/types/staffTypes';
import moment from 'moment';

export const salespersonRole: StaffRoleEnum[] = [
  StaffRoleEnum.Admin,
  StaffRoleEnum.SeniorSeniorManager,
  StaffRoleEnum.SeniorManager,
  StaffRoleEnum.Manager,
  StaffRoleEnum.Salesperson,
];

export const paymentModeFormInitialValues = {
  paymentMode: PaymentModeEnum.Online,
  paymentterm: PaymentTermEnum.Single,
};

export const defaultCachingConfiguration: QueryHookOptions = {
  defaultOptions: {
    fetchPolicy: 'cache-first',
  },
};

export const defaultMobileCachingConfiguration = defaultCachingConfiguration;

export const cachingIsEnabled =
  defaultCachingConfiguration.defaultOptions?.fetchPolicy == 'cache-first' ||
  defaultMobileCachingConfiguration.defaultOptions?.fetchPolicy == 'cache-first';

export const defaultBackdatedDate = moment().subtract(3, 'days').startOf('day');
