import { gql } from '@apollo/client';

export const GET_APPROVALS_COUNT = gql`
  query Approvals($input: GetApprovalsInput!) {
    approvals(input: $input) {
      paginationInfo {
        totalItems
      }
    }
  }
`;

export const GET_APPROVALS = gql`
  query Approvals($input: GetApprovalsInput!) {
    approvals(input: $input) {
      approvals {
        id
        type
        createdAt
        sale {
          id
          type
          expirationDate
          paymentType
          originalCost
          otherDiscounts
          bookingAmount
          finalCost
          licensingFee
          lot {
            id
            name
            resale {
              id
              customerAdminFee
            }
          }
          refund {
            id
            customerAdminFee
          }
          products {
            product {
              productId
            }
          }
          bookingExtensions {
            extensionNo
            extensionPeriod
            status
            bookingFee
            adminFee
            extendedToDate
            requestedDate
            approvedOrRejectedDate
          }
          editBooking {
            id
            customerAdminFee
          }
        }
        allApproverRoles
        mainApproverRole
        salesPerson {
          firstName
          lastName
          uid
        }
        matrix {
          id
          type
          allowed
          parameter
          condition
          matrixNo
          min
          max
          amount
          amountType
          adminFee
          adminFeeType
        }
        approvalExtension {
          paymentInterestParentId
          paymentInterestPayingAmount
          paymentInterestWaiverType
          saleDiscountApprovalLimit {
            amount
            condition
          }
          backdate
        }
        latePaymentInterestExtras {
          dueInterest
          interestToWaive
          waivedInterestToDate
          installmentsToPay {
            id
            amount
          }
          lateInstallmentsToDate {
            id
            amount
          }
          overDueInstallments {
            id
            amount
          }
        }
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
    }
  }
`;

export const GET_APPROVAL = gql`
  query Approval($approvalId: ID!) {
    approval(id: $approvalId) {
      id
      type
      createdAt
      sale {
        id
        expirationDate
        park {
          id
          name
        }
        lot {
          id
          productId
          name
          oldName
          status
          area
          type
          direction
          description
          licensingFee
          continuityFee
          aluminiumEmboss
          backwallWalkway
          costInstantCase
          costPreNeedFullPayment
          costPreNeedInstalment
          offerTombPackage
          tombPackagePrice
          downPayment
          lot {
            zone {
              id
              title
            }
          }
          resale {
            id
            customerAdminFee
          }
        }
        refund {
          id
          customerAdminFee
        }
        bookingExtensions {
          extensionNo
          extensionPeriod
          status
          bookingFee
          adminFee
          extendedToDate
          requestedDate
          approvedOrRejectedDate
        }
        editBooking {
          id
          customerAdminFee
        }
        agent {
          id
          fullName
          firstName
          lastName
        }
        customer {
          id
          firstName
          lastName
        }
        licensingFee
        aluminiumEmboss
        backwallWalkway
        continuityFee
        tombPackagePrice
        purchaseType
        paymentType
        years
        months
        originalCost
        promotionAmount
        agentDiscount
        otherDiscounts
        bookingAmount
        finalCost
        downPayment
      }
      allApproverRoles
      mainApproverRole
      salesPerson {
        firstName
        lastName
        uid
      }
      matrix {
        id
        type
        allowed
        parameter
        condition
        matrixNo
        min
        max
        amount
        amountType
        adminFee
        adminFeeType
      }
      approvalExtension {
        saleDiscountApprovalLimit {
          amount
          condition
        }
        paymentInterestPayingAmount
        paymentInterestWaiverType
        backdate
      }
      latePaymentInterestExtras {
        dueInterest
        interestToWaive
        waivedInterestToDate
        installmentsToPay {
          id
          amount
        }
        lateInstallmentsToDate {
          id
          amount
        }
        overDueInstallments {
          id
          amount
        }
      }
    }
  }
`;
